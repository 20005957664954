<template>
  <div class="base-setup" @click="showIconSelect = false">
    <el-form ref="baseSetting" :model="setup" label-position="top" label-width="80px" inline-message>
      <el-form-item label="模版图标">
        <!-- <i :class="setup.icon" :style="'background:' + setup.background"></i> -->
        <span class="change-icon">
					<span>
						<span>选择背景色</span>
						<el-color-picker v-model="setup.background" show-alpha size="small" :predefine="colors"></el-color-picker>
					</span>
					<span>
						<span>选择图标</span>
						<el-popover placement="bottom-start" width="390" trigger="click">
							<div class="icon-select">
								<i :class="i" v-for="(i, id) in icons" :key="id" @click="setup.icon = i"></i>
							</div>
              <i slot="reference" :class="setup.icon"></i>
            </el-popover>
					</span>
				</span>
      </el-form-item>
      <el-form-item label="模板名称" :rules="getRule('请输入模板名称')" prop="templateName">
        <el-input v-model="setup.templateName" size="medium"></el-input>
      </el-form-item>
      <el-form-item label="所在分组" :rules="getRule('请选择模板分组')" class="group" prop="groupId">
        <el-select v-model="setup.groupId" placeholder="请选择分组" size="medium">
          <el-option v-for="(option, index) in groupOptions" :key="index" v-show="option.statusId !==0"
                     :label="option.groupName" :value="option.groupId"></el-option>
        </el-select>
        <el-popover placement="bottom-end" title="新建模板分组" width="300" trigger="click">
          <el-input size="medium" v-model="newGroup" placeholder="请输入新的分组名">
            <el-button slot="append" size="medium" type="primary" @click="addGroup">提交</el-button>
          </el-input>
          <el-button icon="el-icon-plus" slot="reference" size="medium" type="primary">新建分组</el-button>
        </el-popover>
      </el-form-item>
      <el-form-item label="关联的业务菜单" :rules="getRule('请选择关联的业务菜单')" prop="menuId">
        <el-cascader
            style="width: 100%"
            v-model="setup.menuId"
            :options="cascaderOptions"
            :props="cascaderProps"
            show-all-levels
            size="medium"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="备注说明">
        <el-input placeholder="请填写模版的备注说明" v-model="setup.remark" type="textarea" show-word-limit
                  :autosize="{ minRows: 2, maxRows: 5}" maxlength="500"></el-input>
      </el-form-item>
      <el-form-item label="授权人员">
        <user-select v-model="setup.userAuthority" style="width: 100%" multiple @beforeLoad="beforeUserSelectLoad" @afterLoad="afterUserSelectLoad"></user-select>
      </el-form-item>
      <el-form-item label="授权部门">
        <dept-select v-model="setup.deptAuthority" style="width: 100%" multiple></dept-select>
      </el-form-item>
      <el-form-item label="授权角色">
        <role-select v-model="setup.roleAuthority" style="width: 100%" multiple clearable filterable></role-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import iconfont from '@/assets/iconfont/iconfont.json'

export default {
  name: "FormBaseSetting",
  data() {
    return {
      showIconSelect: false,
      newGroup: '',
      groupOptions: [],
      colors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      icons: [
        'el-icon-delete-solid',
        'el-icon-s-tools',
        'el-icon-s-goods',
        'el-icon-warning',
        'el-icon-circle-plus',
        'el-icon-camera-solid',
        'el-icon-s-promotion',
        'el-icon-s-cooperation',
        'el-icon-s-platform',
        'el-icon-s-custom',
        'el-icon-s-data',
        'el-icon-s-check',
        'el-icon-s-claim',
        'el-icon-picture-outline',
        'el-icon-picture',
        'el-icon-s-finance',
        'el-icon-s-grid',
        'el-icon-menu',
        'el-icon-tickets',
        'el-icon-collection',
        'el-icon-notebook-1',
        'el-icon-coin',
      ],
      rules: {
        templateName: [{}],
        groupId: [],
      },
      cascaderOptions: [],
      cascaderProps: {
        expandTrigger: 'hover',
        emitPath: false
      },
      loading: ''
    }
  },
  computed: {
    setup() {
      return this.$store.state.design;
    }
  },
  created() {
    this.loadCascaderOptions()
    this.loadIconfont()
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    getRule(msg) {
      return [{required: true, message: msg, trigger: 'blur'}]
    },
    loadCascaderOptions() {
      this.$http.get('/flowable/admin/form/cascadeBindingData').then(({data: res}) => {
        if (res.code === 0) {
          this.cascaderOptions = res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    loadIconfont() {
      if (iconfont && iconfont.id) {
        iconfont.glyphs.forEach(icon => {
          this.icons.push(`${iconfont.font_family} ${iconfont.css_prefix_text}${icon.font_class}`)
        })
      }
    },
    getGroups(selectGroupId) {
      this.$http.get('/flowable/admin/form/onlyGroup').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.groupOptions = res.data
        if (selectGroupId) {
          this.setup.groupId = selectGroupId
        }
      }).catch(err => this.$message.error('获取分组异常'))
    },
    addGroup() {
      if (this.newGroup.trim() !== '') {
        this.$http.post(`/flowable/admin/form/group`, null, {params: {name: this.newGroup.trim()}}).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('操作成功')
          // 后台重新查询模板分组，并默认选中当前新建的模板分组
          let groupId = res.data ? res.data.groupId : null
          this.getGroups(groupId)
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    validate() {
      this.$refs.baseSetting.validate()
      let err = []
      if (!this.$isNotEmpty(this.setup.templateName)) {
        err.push('模板名称未设置')
      }
      if (!this.$isNotEmpty(this.setup.menuId)) {
        err.push('关联的业务菜单未设置')
      }
      if (!this.$isNotEmpty(this.setup.groupId)) {
        err.push('模板分组未设置')
      }
      return err
    },
    clearValidate() {
      this.$refs.baseSetting.clearValidate()
    },
    // 人员加载时显示遮罩，避免用户在人员信息尚未加载完毕时就点击发布按钮
    beforeUserSelectLoad() {
      this.loading = this.$loading({
        lock: true,
        target: '.base-setup',
        text: '授权人员正在加载.....',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    afterUserSelectLoad() {
      this.loading.close()
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-select-dropdown {
  display: none;
}

.icon-select {
  display: flex;
  flex-wrap: wrap;

  i {
    cursor: pointer;
    font-size: large;
    padding: 10px;
    max-width: 38px !important;

    &:hover {
      box-shadow: 0 0 10px 2px #C2C2C2;
    }
  }
}

/deep/ .select-u {
  width: 100%;
}

.base-setup {
  overflow: auto;
  margin: 0 auto;
  width: 600px;
  height: calc(100vh - 105px);
  background: #ffffff;
  margin-top: 10px;
  padding: 15px 20px;

  i:first-child {
    position: relative;
    cursor: pointer;
    font-size: xx-large;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
  }

  .change-icon {
    //margin-left: 20px;

    span {
      font-size: small;
      color: #7a7a7a;
      margin-right: 15px;
    }

    i {
      cursor: pointer;
      color: #7a7a7a;
      font-size: x-large;
    }
  }

  /deep/ .el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
}

/deep/ .group {
  .el-select {
    width: calc(100% - 130px);
  }

  .el-button {
    margin-left: 10px;
    width: 120px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
