<template>
  <div>
    <div class="header">
      <el-menu :default-active="value" active-text-color="#409eff" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="baseSetting" @click="to('baseSetting')">① 基础信息</el-menu-item>
        <el-menu-item index="processDesign" @click="to('processDesign')">② 审批流程</el-menu-item>
      </el-menu>
      <div class="publish">
        <el-button size="mini" type="primary" @click="publish"><i class="el-icon-s-promotion"></i>发布</el-button>
      </div>
      <div class="back">
        <el-button @click="exit" size="medium" icon="el-icon-arrow-left" circle></el-button>
        <span>
          <i :class="setup.icon" :style="'background:' + setup.background"></i>
          <span>{{ setup.templateName }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LayoutHeader",
  props:{
    value: {
      type: String,
      default: 'baseSetup'
    }
  },
  computed: {
    setup() {
      return this.$store.state.design
    }
  },
  mounted() {
    if (document.body.offsetWidth <= 970) {
      this.$msgbox.alert("本设计器未适配中小屏幕，建议您在PC电脑端浏览器进行操作")
    }
  },
  methods: {
    publish() {
      this.$emit('publish')
    },
    exit() {
      this.$emit('exit')
    },
    to(path) {
      this.$emit('input', path)
    },
  }
}
</script>
<style lang="less" scoped>

/deep/ .header {
  min-width: 980px;
  position: relative;

  .el-menu {
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .publish {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000;

    i {
      margin-right: 6px;
    }

    button {
      border-radius: 15px;
    }
  }

  .back {
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 20px;
    font-size: small;

    span {
      i {
        border-radius: 10px;
        padding: 7.8px;
        font-size: 20px;
        color: #ffffff;
        margin: 0 10px;
      }
    }

  }
}
</style>
