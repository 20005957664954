var render, staticRenderFns
import script from "./ProcessTree.vue?vue&type=script&lang=js"
export * from "./ProcessTree.vue?vue&type=script&lang=js"
import style0 from "./ProcessTree.vue?vue&type=style&index=0&id=e6084252&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6084252",
  null
  
)

export default component.exports