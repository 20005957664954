<template>
  <div class="from-panel" ref="panel">
    <div class="from-title">
      <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getGroups()">
          <el-form-item>
            <el-input v-model="dataForm.templateName" placeholder="模板名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <toolbar-button role="query" @click="getGroups()"></toolbar-button>
            <toolbar-button v-if="$hasPermission('flw:template:save')" name="新建模板" icon="el-icon-plus" @click="newTemplate"></toolbar-button>
            <toolbar-button v-if="$hasPermission('flw:templategroup:save')" name="新建分组" icon="el-icon-plus" @click="addGroup"></toolbar-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <draggable :list="groups" group="group" handle=".el-icon-rank" filter=".undrag" @start="groupsSort = true"
               :options="{animation: 300, sort: true, scroll: true, chosenClass: 'choose'}" @end="groupSort">
      <div :class="{'form-group':true, 'undrag': (group.statusId === 0 || group.id === undefined)}"
           v-show="showGroup(group)" v-for="(group, gidx) in groups" :key="gidx">
        <div class="form-group-title">
          <span>{{group.name}}</span>
          <span>({{group.items.length}})</span>
          <i class="el-icon-rank" title="分组可拖拽排序"></i>
          <div v-if="canEditGroup(group)">
            <el-button v-if="$hasPermission('flw:templategroup:update')" type="text" icon="el-icon-edit-outline" @click.native="editGroup(group)">修改</el-button>
            <el-button v-if="$hasPermission('flw:templategroup:delete')" type="text" icon="el-icon-delete" @click.native="delGroup(group)">删除</el-button>
          </div>
        </div>
        <draggable style="width: 100%; min-height:25px" :list="group.items" group="from" @end="groupSort" v-show="!groupsSort" filter=".undrag"
                   :options="{animation: 300, delay: 200, chosenClass: 'choose', scroll: true, sort: true}">
          <div :class="{'form-group-item': true, 'undrag': item.isStop}" v-for="(item, index) in group.items"
               :key="index" title="按住该行长按1秒后可拖拽排序">
            <div>
              <i :class="item.icon" :style="'background: '+item.background"></i>
              <span>{{item.templateName}}</span><br>
            </div>
            <div class="desp">{{item.remark}}</div>
            <div>
              <span>更新于：{{item.updated}}</span>
            </div>
            <!-- 由于父div（用于拖拽的）设置了title属性，导致里面这些按钮也会显示该标题，会误导用户，因此特意将此处div的title设置为空 -->
            <div title="">
              <el-button v-if="$hasPermission('flw:template:update')" type="text" icon="el-icon-edit-outline" size="mini" @click="editTemplate(item)">编辑</el-button>
              <el-button v-if="$hasPermission('flw:template:copy')" type="text" icon="el-icon-document-copy" size="mini" @click="openTemplateCopyDialog(item)">复制</el-button>
              <el-button v-if="$hasPermission('flw:template:update')" type="text" :icon="item.isStop ? 'el-icon-check':'el-icon-close'" size="mini" @click="stopFrom(item)">{{item.isStop ? '启用' : '停用'}}</el-button>
              <el-button v-if="$hasPermission('flw:template:delete')&&item.isStop" type="text" icon="el-icon-delete" size="mini" @click="moveFrom(item)">删除</el-button>
              <el-popover placement="left" trigger="click" width="400" style="margin-left: 10px" @show="moveSelect === null">
                <div style="max-height: 200px; overflow: auto;">
                  <el-radio-group v-model="moveSelect" size="mini">
                    <el-radio :label="g.id" border v-for="g in groups" :key="g.id" v-show="g.statusId !== 0 && g.statusId !== 1" :disabled="g.id === group.id" style="margin: 10px;">{{g.name}}</el-radio>
                  </el-radio-group>
                </div>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="moveFrom(item)">提交</el-button>
                </div>
                <el-button v-if="showMove(group)" slot="reference" type="text" icon="el-icon-s-promotion" size="mini">移动</el-button>
              </el-popover>
            </div>
          </div>
        </draggable>
        <div v-if="canEditGroup(group)" style="text-align: center">
          <el-button v-if="$hasPermission('flw:template:save')" style="padding-top: 0" type="text" icon="el-icon-plus" @click="newTemplate(group.id)">新建模板</el-button>
        </div>
      </div>
    </draggable>
    <vxe-modal ref="designModal" :title="processTemplateDesignTitle" :zIndex="1500" v-model="processTemplateDesignVisible" :showHeader="false" destroy-on-close fullscreen :before-hide-method="beforeProcessTemplateDesignClose">
      <process-template-design ref="processTemplateDesign" @submit="processTemplateDesignSubmit" @exit="$refs.designModal.close()"></process-template-design>
    </vxe-modal>
    <!-- 流程模板复制-->
    <process-template-copy v-if="processTemplateCopyVisible" ref="processTemplateCopy" @refreshDataList="getGroups" @close="closeTemplateCopyDialog"></process-template-copy>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import ProcessTemplateDesign from "@/views/modules/flowable/admin/ProcessTemplateDesign"
import ProcessTemplateCopy from "@/views/modules/flowable/admin/ProcessTemplateCopy"

export default {
  name: "ProcessTemplate",
  components: {draggable, ProcessTemplateDesign,ProcessTemplateCopy},
  data() {
    return {
      moveSelect: '',
      visible: false,
      groupsSort: false,
      groups: [],
      processTemplateDesignVisible: false,
      processTemplateDesignTitle: '',
      dataForm:{
        templateName: '' // 流程模板名称
      },
      processTemplateCopyVisible: false,
    }
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    getGroups() {
      this.$http.get(`/flowable/admin/form/group`, {
        params: this.dataForm
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.groups = res.data
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    newTemplate(groupId) {
      this.processTemplateDesignTitle = '新建模板'
      this.processTemplateDesignVisible = true
      this.$nextTick(() => {
        this.$refs.processTemplateDesign.setup.groupId = groupId
        this.$refs.processTemplateDesign.init()
      })
    },
    editTemplate(item) {
      this.processTemplateDesignTitle = '编辑模板'
      this.processTemplateDesignVisible = true
      this.$nextTick(() => {
        this.$refs.processTemplateDesign.templateId = item.templateId
        this.$refs.processTemplateDesign.init()
      })
    },
    groupSort() {
      this.groupsSort = false
      this.$http.put('/flowable/admin/form/group/sort', this.groups).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success('操作成功')
        this.getGroups()
      }).catch(err => {
        this.getGroups()
        this.$message.error(err.response.data)
      })
    },
    addGroup() {
      this.$prompt('请输入要添加的组名', '新的分组名', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
        inputErrorMessage: '分组名不能为空且长度小于30',
        inputPlaceholder: '请输入分组名'
      }).then(({value}) => {
        this.$http.post(`/flowable/admin/form/group`, null, {params: {name: value}}).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('操作成功')
          this.getGroups()
        }).catch(() => {
          return this.$message.error('出错了')
        })
      })
    },
    delGroup(group) {
      this.$confirm('删除后组中的流程模版将被转移到“其他”分组中，确定要删除分组[' + group.name + ']?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(`/flowable/admin/form/group/${group.id}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('操作成功')
          this.getGroups()
        }).catch(() => {
          return this.$message.error('出错了')
        })
      })
    },
    editGroup(group) {
      this.$prompt('请输入新的分组名称', '修改分组名', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,50}$/,
        inputErrorMessage: '分组名不能为空且长度小于50',
        inputPlaceholder: '请输入分组名',
        inputValue: group.name
      }).then(({value}) => {
        this.$http.put(`/flowable/admin/form/group`, null, {params: {id: group.id, name: value}}).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('操作成功')
          this.getGroups()
        }).catch(() => {
          return this.$message.error('出错了')
        })
      })
    },
    updateForm(item, type) {
      this.$http.put(`/flowable/admin/form`, null, {params: {templateId: item.templateId, type: type}}).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success('操作成功')
        this.getGroups()
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    getTemplateData(data, group){
      return data
    },
    stopFrom(item) {
      let tip = item.isStop ? ' 启用后将会进入 “其他” 分组，是否继续？' : ' 停用后将被转移到 “已停用” 分组，是否继续?';
      this.$confirm(`<span style="font-weight: bold">[${item.templateName}]</span>` + tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.updateForm(item, (item.isStop ? 'using' : 'stop'));
      })
    },
    moveFrom(item) {
      if (item.isStop) {
        this.$confirm(`您确定要删除 <span style="font-weight: bold">[${item.templateName}]</span>> 吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }).then(() => {
          this.$http.delete(`/flowable/admin/form/${item.templateId}`).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message.success('操作成功')
            this.getGroups()
          }).catch(() => {
            return this.$message.error('出错了')
          })
        })
      } else {
        if (this.moveSelect === null || this.moveSelect === ''){
          this.$message.error('请选择分组')
          return;
        }

        this.$http.put(`/flowable/admin/form`, null, {params: {templateId: item.templateId, type: 'move', groupId: this.moveSelect}}).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('操作成功')
          this.getGroups()
          this.moveSelect = null
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    // 流程模板新增或编辑提交
    processTemplateDesignSubmit() {
      this.processTemplateDesignVisible = false
      this.getGroups()
    },
    // 关闭流程模板编辑弹窗时，弹出询问
    beforeProcessTemplateDesignClose() {
      // 如果processTemplateDesignVisible为false，表示此次关闭是模板提交后自动关闭弹窗，因此不需要提示
      if (this.processTemplateDesignVisible) {
        return new Promise((resolve, reject) => {
          this.$confirm('您确定不保存修改，直接退出吗?', '提示', {
            confirmButtonText: '退出',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            resolve()
          }).catch(() => {
            reject()
          })
        })
      }
    },
    canEditGroup(group){
      // 停用分组、其他分组默认不能编辑
     return !(group.statusId === 0 || group.statusId === 1 || group.id === undefined)
    },
    showGroup(group){
      // 停用分组、其他分组内没流程模板则不显示
      return !(group.statusId === 0 || group.statusId === 1 || group.id === undefined)? true : group.items.length > 0
    },
    showMove(group){
      // 停用分组 不能移动
      return !(group.statusId === 0 || group.id === undefined) && this.$hasPermission('flw:template:update')
    },
    // 打开流程模板复制弹窗
    openTemplateCopyDialog(item){
      this.processTemplateCopyVisible = true
      this.$nextTick(() => {
        this.$refs.processTemplateCopy.dataForm.templateId = item.templateId
        this.$refs.processTemplateCopy.init()
      })
    },
    // 关闭流程模板复制弹窗
    closeTemplateCopyDialog(){
      this.processTemplateCopyVisible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.undrag {
  background: #ebecee !important;
}

.from-panel {
  padding: 20px 20px;
  min-width: 500px;
  background: #ffffff;

  /deep/ .from-title {
    div {
      //float: right;

      .el-button {
        border-radius: 15px;
      }
    }
  }

  //height: 100vh;
}

.choose {
  background: #e9ebee;
}

.form-group {
  margin: 20px 0;
  padding: 5px 0px;
  border-radius: 10px;
  //border: 1px solid #d3d3d3;
  box-shadow: 1px 1px 10px 0 #d2d2d2;

  &:hover {
    box-shadow: 1px 1px 12px 0 #b3b3b3;
  }

  .form-group-title {
    padding: 5px 20px;
    //height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #d3d3d3;

    .el-icon-rank {
      display: none;
      cursor: move;
    }

    &:hover {
      .el-icon-rank {
        display: inline-block;
      }
    }

    div {
      display: inline-block;
      float: right;
    }

    span:first-child {
      margin-right: 5px;
      font-size: 15px;
      font-weight: bold;
    }

    span:nth-child(2) {
      color: #656565;
      font-size: small;
      margin-right: 10px;
    }

    /deep/ .el-button {
      color: #909399;
      margin-left: 20px;

      &:hover {
        color: #909399;
      }
    }
  }

  .form-group-item:nth-child(1) {
    border-top: none !important;
  }

  .form-group-item {
    color: #3e3e3e;
    font-size: small;
    padding: 10px 0;
    margin: 0 20px;
    height: 60px;
    position: relative;
    line-height: 40px;
    border-top: 1px solid #d3d3d3;

    div {
      display: inline-block;
    }

    i {
      border-radius: 10px;
      padding: 7px;
      font-size: 20px;
      color: #ffffff;
      margin-right: 10px;
    }

    div:nth-child(1) {
      float: left;
    }

    div:nth-child(2) {
      position: absolute;
      color: #7a7a7a;
      font-size: 12px;
      left: 300px;
      max-width: 300px;
      overflow: hidden;
    }

    div:nth-child(3) {
      position: absolute;
      color: #7a7a7a;
      right: 20%;
    }

    div:nth-child(4) {
      float: right;
    }
  }
}

@media screen and (max-width: 1000px) {
  .desp {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .from-panel{
    padding: 50px 10px;
  }
}
</style>
