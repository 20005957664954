<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="复制" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="模板名称" prop="templateName">
            <el-input v-model="dataForm.templateName" placeholder="模板名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所在分组" prop="groupId">
            <el-select v-model="dataForm.groupId" placeholder="请选择分组" size="medium" class="w-percent-100">
              <el-option v-for="(option, index) in groupOptions" :key="index" v-show="option.statusId !==0" :label="option.groupName" :value="option.groupId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务菜单" prop="menuId">
            <el-cascader class="w-percent-100" v-model="dataForm.menuId" :options="cascaderOptions" :props="cascaderProps" show-all-levels size="medium"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input placeholder="备注说明" v-model="dataForm.remark" type="textarea" show-word-limit :autosize="{ minRows: 2, maxRows: 10}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        templateId: '',
      },
      groupOptions: [], // 流程分组集合
      cascaderOptions: [], // 流程业务菜单集合
      cascaderProps: {
        expandTrigger: 'hover',
        emitPath: false
      },
    }
  },
  computed: {
    dataRule() {
      return {
        templateName: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        groupId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        menuId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.loadCascaderOptions()
    this.getGroups()
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.templateId) {
          this.getInfo()
        }
      })
    },
    // 加载流程的业务菜单
    loadCascaderOptions() {
      this.$http.get('/flowable/admin/form/cascadeBindingData').then(({data: res}) => {
        if (res.code === 0) {
          this.cascaderOptions = res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 获取流程模板分组
    getGroups() {
      this.$http.get('/flowable/admin/form/onlyGroup').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.groupOptions = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/flowable/admin/form/detail/' + this.dataForm.templateId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.dataForm.templateName = this.dataForm.templateName + ' [复制]'
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http['post']('/flowable/admin/form/copy', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          //  do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>