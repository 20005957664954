<template>
  <el-container>
    <el-header style="background: white">
      <layout-header v-model="activeSelect" @publish="publishProcess" @exit="exit"></layout-header>
    </el-header>
    <div class="layout-body">
      <form-base-setting ref="baseSetting" v-show="activeSelect === 'baseSetting'"/>
      <process-design ref="processDesign" v-show="activeSelect === 'processDesign'"/>
    </div>
    <vxe-modal v-model="validVisible" title="表单校验" width="50%" destroy-on-close>
      <el-steps align-center :active="validStep" finish-status="success">
        <el-step v-for="(step, i) in validOptions" 
            :title="step.title" :key="i"
            :icon="step.icon" 
            :status="step.status" 
            :description="step.description"/>
      </el-steps>
      <el-result :icon="validIcon" :title="errTitle" :subTitle="validResult.desc">
        <i slot="icon" style="font-size: 30px" v-if="!validResult.finished" class="el-icon-loading"></i>
        <div slot="subTitle" class="err-info" v-if="validResult.errs && validResult.errs.length > 0">
          <ellipsis hover-tip v-for="(err, i) in validResult.errs" :key="i + '_err'" :content="err">
            <i slot="pre" class="el-icon-warning-outline"></i>
          </ellipsis>
        </div>
        <template slot="extra">
          <el-button type="primary" v-if="validResult.finished" size="medium" @click="doAfter">
            {{ validResult.action }}
          </el-button>
        </template>
      </el-result>
    </vxe-modal>
  </el-container>
</template>

<script>
import LayoutHeader from './LayoutHeader'
import FormBaseSetting from '@/views/modules/flowable/admin/layout/FormBaseSetting'
import ProcessDesign from '@/views/modules/flowable/admin/layout/ProcessDesign'

export default {
  name: "ProcessTemplateDesign",
  components: {LayoutHeader, FormBaseSetting, ProcessDesign},
  data() {
    return {
      dialogTitle: '',
      templateId: '',
      isNew: true,
      validStep: 0,
      timer: null,
      activeSelect: 'baseSetting',
      validVisible: false,
      validResult: {},
      validOptions: [
        {title: '基础信息', description: '', icon: '', status: ''},
        {title: '流程模版', description: '', icon: '', status: ''},
      ],
      validComponents: ['baseSetting', 'processDesign'],
    }
  },
  computed: {
    setup() {
      return this.$store.state.design
    },
    errTitle(){
      if (this.validResult.finished && !this.validResult.success){
        return this.validResult.title + ` (${this.validResult.errs.length} 项错误) 😥`
      }
      return this.validResult.title
    },
    validIcon() {
      if (!this.validResult.finished) {
        return 'el-icon-loading'
      } else if (this.validResult.success) {
        return 'success'
      } else {
        return 'warning'
      }
    }
  },
  beforeDestroy() {
    this.stopTimer()
  },
  methods: {
    init() {
      this.loadInitForm()
      this.$nextTick(() => {
        this.clearValidateResult()
        //判断传参，决定是新建还是加载原始数据
        if (this.$isNotEmpty(this.templateId)) {
          this.isNew = false
          this.loadTemplateInfo(this.templateId)
        }
      })
    },
    loadTemplateInfo(templateId) {
      this.$http.get(`/flowable/admin/form/detail/${templateId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let templateInfo = res.data;
        templateInfo.process = JSON.parse(templateInfo.process)
        this.$store.commit('templateDesignInfo', templateInfo)
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    loadInitForm() {
      this.$store.commit('templateDesignInfo', {
        templateId: null,
        templateName: "",
        icon: "el-icon-eleme",
        background: "#1e90ff",
        groupId: this.setup.groupId,
        process: {
          id: "root",
          parentId: null,
          type: "ROOT",
          name: "发起人",
          desc: "任何人",
          props: {
            assignedUser: [],
            formPerms: []
          },
          children: {}
        },
        remark: "",
      })
    },
    validateDesign() {
      this.validVisible = true
      this.validStep = 0
      this.clearValidateResult()
      this.stopTimer()
      this.timer = setInterval(() => {
        this.validResult.errs = this.$refs[this.validComponents[this.validStep]].validate()
        if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
          this.validStep++;
          if (this.validStep >= this.validOptions.length) {
            this.stopTimer()
            this.showValidFinish(true)
          }
        } else {
          this.stopTimer()
          this.validOptions[this.validStep].status = 'error'
          this.showValidFinish(false, this.getDefaultValidErr())
          // elementui表单校验的错误提示信息会与当前窗口冲突，所以先清除掉
          this.$refs.baseSetting.clearValidate()
        }
      }, 300)
    },
    getDefaultValidErr() {
      switch (this.validStep) {
        case 0:
          return '请检查基础设置项';
        case 1:
          return '请检查流程图，查看对应标注节点错误信息'
        default:
          return '未知错误'
      }
    },
    showValidFinish(success, err) {
      this.validResult.success = success
      this.validResult.finished = true
      this.validResult.title = success ? '校验通过' : '校验失败'
      this.validResult.desc = success ? '校验成功，是否提交？' : err
      this.validResult.action = success ? '提 交' : '去修改'
    },
    // 清空校验结果
    clearValidateResult() {
      this.validResult = {
        errs: [],
        finished: false,
        success: false,
        title: '检查中...',
        action: '处理',
        desc: '正在检查设置项'
      }
      this.validStep = 0
      this.validOptions.forEach(op => {
        op.status = ''
        op.icon = ''
        op.description = ''
      })
    },
    doAfter() {
      if (this.validResult.success) {
        this.doPublish()
      } else {
        this.activeSelect = this.validComponents[this.validStep]
        if (this.activeSelect == 'baseSetting') {
          this.$refs.baseSetting.validate()
        }
        this.validVisible = false
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    publishProcess() {
      this.validateDesign()
    },
    doPublish() {
      this.$confirm('点击发布立即生效，是否继续?', '提示', {
        confirmButtonText: '发布',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let processNew =  JSON.parse(JSON.stringify(this.setup.process))

        // 判断条件分支
        this.conditionRecursion(processNew)

        let template = {
          templateId: this.setup.templateId,
          templateName: this.setup.templateName,
          icon: this.setup.icon,
          background: this.setup.background,
          groupId: this.setup.groupId,
          process: JSON.stringify(processNew),
          remark: this.setup.remark,
          menuId: this.setup.menuId,
          userAuthority: this.setup.userAuthority,
          deptAuthority: this.setup.deptAuthority,
          roleAuthority: this.setup.roleAuthority
        }
        const loading = this.$loading({
          lock: true,
          text: '提交中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        if (this.isNew || !this.$isNotEmpty(this.setup.templateId)) {
          this.$http.post(`/flowable/admin/form`, template).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message.success("创建成功")
            this.$emit('submit')
          }).catch(() => {
            return this.$message.error('执行失败，请稍后重试！')
          }).finally(() => {
            loading.close()
          })
        } else {
          this.$http.put(`/flowable/admin/form/detail`, template).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message.success("更新成功")
            this.$emit('submit')
          }).catch(() => {
            return this.$message.error('执行失败，请稍后重试！')
          }).finally(() => {
            loading.close()
          })
        }
      })
    },
    conditionRecursion(process){
      if(null != process && undefined != process){
        if(null != process.branchs && undefined != process.branchs){
          process.branchs.map((item, i) => {
            if (i == process.branchs.length - 1) {
              item.typeElse = true;
            } else {
              item.typeElse = false;
            }
            if(null != item.children && undefined != item.children){
              this.conditionRecursion(item.children)
            }else{
              return item;
            }
          })
        }
        this.conditionRecursion(process.children)
      }
    },
    exit() {
      this.$emit('exit')
    }
  }
}
</script>

<style lang="less" scoped>

.layout-body {
  min-width: 980px;
}

/deep/ .el-step {
  .is-success {
    color: #2a99ff;
    border-color: #2a99ff;
  }
}

.err-info{
  max-height: 180px;
  overflow-y: auto;
  & > div{
    padding: 5px;
    margin: 2px 0;
    width: 220px;
    text-align: left;
    border-radius: 3px;
    background: rgb(242 242 242);
  }
  i{
    margin: 0 5px;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}

</style>
