<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="工程或费用名称" prop="subItemId">
            <sub-item-select v-model="dataForm.subItemId" :contract-id="measForm.contractId" :subcontractor-id="measForm.subcontractorId" @change="subItemSelectChangeHandle" :disabled="dataForm.id != ''"></sub-item-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="计量部位" prop="name">
            <el-input v-model="dataForm.name" placeholder="请填写部位名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" v-if="dataForm.subItemName">
          <el-form-item label="名称路径" prop="subItemName">
            {{ dataForm.subItemName }}
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="起讫桩号" prop="stakeNo">
            <el-input v-model="dataForm.stakeNo" placeholder="请填写起讫桩号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="交工证书" prop="ctoa">
            <el-input v-model="dataForm.ctoa" placeholder="交工证书号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分部分项" prop="wbsId">
            <wbs-select v-model="dataForm.wbsId" :contractId="measForm.contractId" :subcontractor-id="measForm.subcontractorId" placeholder="选择分部分项" showPNames></wbs-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图号" prop="drawingNo">
            <el-input v-model="dataForm.drawingNo" placeholder="请填写图号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="计算式" prop="formula">
            <el-input type="textarea" v-model="dataForm.formula" placeholder="请填写计算式" :autosize="{ minRows: 2 }"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="计量单号" prop="code">
            <el-input v-model="dataForm.code" :placeholder="dataForm.id === '' ? '可自动生成' : ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        measId: '',
        code: '',
        formula: '',
        name: '',
        stakeNo: '',
        drawingNo: '',
        subItemId: '',
        subItemName: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      },
      measForm: {
        contractId: '',
        subcontractorId: '',
        periodId: '',
      },
      title: '计量申请',
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        code: [
          { required: this.dataForm.id !== '', message: this.$t('validate.required'), trigger: 'blur' }
        ],
        subItemId: [
          { required: true, message: this.$t('validate.required'), trigger: 'change' }
        ],
      }
    },
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/measTicket/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/measTicket/', {...this.dataForm, ...this.measForm}).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList', this.dataForm.subItemId)
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false }),
    subItemSelectChangeHandle(newSubItem) {
      this.dataForm.subItemName = `${newSubItem.pnames}/${newSubItem.name}`
    }
  }
}
</script>